import {
  Actions,
  IMassiveOperationState,
  MassiveOperationsActionTypes,
} from './types';

export const initialState: IMassiveOperationState = {};

export function massiveOperationsReducer(
  state = { ...initialState },
  action: Actions
): IMassiveOperationState {
  switch (action.type) {
    case MassiveOperationsActionTypes.ADD_ORDERS: {
      return { ...action.payload };
    }
    case MassiveOperationsActionTypes.UPDATE_ORDER: {
      const { id, order } = action.payload;
      return { ...state, [id]: { ...order } };
    }
    case MassiveOperationsActionTypes.RESET_ORDERS_OPERATIONS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
