import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import {
  INewPlannedOperation,
  ITaskType,
  IOperation,
  IPlannedOperation,
  IHistory,
  IStatusData,
  IOrderState, INewTaskType, IEditedTaskType, IOrder, OrderNumberField, IOrderNotification, INewOrderNotification
} from '@store/modules/orders/types'
import { IParams, ISearchParams, ISuccessfulRequest } from '@store/types/commonTypes'
import { ETag } from '@common/cache/types'

class OrdersService extends AbstractHttpService {
  private readonly url = urls.orders

  public async getOrders (
    params: IParams | ISearchParams
  ): Promise<AxiosResponse<Array<IOrder<OrderNumberField>>>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getOrder (
    orderOguid: string,
  ): Promise<AxiosResponse<IOrder<OrderNumberField>>> {
    const url = this.url.single.replace('{{ oguid }}', orderOguid)

    return await this._http.get(url)
  }

  public async addOrder (
    data: any,
    params?: IParams
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.post(url, data, config)
  }

  public async editOrder (
    orderOguid: string,
    data: string,
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ oguid }}', orderOguid)

    return await this._http.put(url, data)
  }

  public async deleteOrder (
    orderOguid: string,
    params?: IParams
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ oguid }}', orderOguid)

    const config: AxiosRequestConfig = {
      params,
    };

    return await this._http.delete(url, params ? config : undefined)
  }

  public async getOperations (orderOguid: string, params?: AxiosRequestConfig): Promise<AxiosResponse<IOperation[]>> {
    const url = this.url.operations.details.replace('{{ oguid }}', orderOguid)
    const config: AxiosRequestConfig = params ? { params } : { params: { page: -1 } }

    return await this._http.get(url, config)
  }

  public async getOrderType (oguid: string): Promise<AxiosResponse<ITaskType>> {
    const url = this.url.types.single.replace('{{ order_type_oguid }}', oguid)

    return await this._http.get(url)
  }

  public async getOrderTypes (params: IParams, headers?: ETag): Promise<AxiosResponse<ITaskType[]>> {
    const url = this.url.types.list

    return await this._http.get(url, { params, headers })
  }

  public async addOrderType (data: INewTaskType): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.types.list

    return await this._http.post(url, data)
  }

  public async editOrderType (orderTypeOguid: string, data: IEditedTaskType): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.types.single.replace('{{ order_type_oguid }}', orderTypeOguid)

    return await this._http.put(url, data)
  }

  public async deleteOrderType (orderTypeOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.types.single.replace('{{ order_type_oguid }}', orderTypeOguid)

    return await this._http.delete(url)
  }

  public async getPlannedOperation (
    orderTypeOguid: string,
    makerOguid: string
  ): Promise<AxiosResponse<IPlannedOperation[]>> {
    const url = this.url.planned_operations.single
      .replace('{{ order_type_oguid }}', orderTypeOguid)
      .replace('{{ maker_oguid }}', makerOguid)

    return await this._http.get(url)
  }

  public async getPlannedOperations (orderTypeOguid: string): Promise<AxiosResponse<IPlannedOperation[]>> {
    const url = this.url.planned_operations.list.replace('{{ order_type_oguid }}', orderTypeOguid)

    return await this._http.get(url)
  }

  public async addPlannedOperation (
    orderTypeOguid: string,
    data: INewPlannedOperation
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.planned_operations.list.replace('{{ order_type_oguid }}', orderTypeOguid)

    return await this._http.post(url, data)
  }

  public async editPlannedOperation (
    makerOguid: string,
    data: INewPlannedOperation
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.planned_operations.maker.replace('{{ maker_oguid }}', makerOguid)

    return await this._http.put(url, data)
  }

  public async deletePlannedOperation (makerOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.planned_operations.maker.replace('{{ maker_oguid }}', makerOguid)

    return await this._http.delete(url)
  }

  public async getHistory (orderOguid: string): Promise<AxiosResponse<IHistory[]>> {
    const url = this.url.history.replace('{{ order_oguid }}', orderOguid)

    return await this._http.get(url)
  }

  public async getDashboard (orderOguid: string): Promise<AxiosResponse<IOperation>> {
    const url = this.url.operations.dashboard.replace('{{ oguid }}', orderOguid)

    return await this._http.get(url)
  }

  public async updateOrderState (orderOguid: string, data: IStatusData, params?: IParams): Promise<AxiosResponse<IOrderState>> {
    const url = this.url.state.replace('{{ order_oguid }}', orderOguid)

    const config: AxiosRequestConfig = {
      params,
    };

    return await this._http.put(url, data, params? config : undefined)
  }

  public async getOrderNotifications (orderOguid: string): Promise<AxiosResponse<IOrderNotification[]>> {
    const url = this.url.notifications.replace('{{ order_oguid }}', orderOguid)

    return await this._http.get(url)
  }

  public async addOrderNotification (
    orderOguid: string,
    data: INewOrderNotification | undefined
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.notifications.replace('{{ order_oguid }}', orderOguid)

    return await this._http.post(url, data)
  }

  public async exportRequests (params: ISearchParams): Promise<AxiosResponse<Blob>> {
    const url = this.url.excel
    const config: AxiosRequestConfig = { responseType: 'blob', params }

    return await this._http.get(url, config)
  }
}

export default new OrdersService()
