import React, { FC, ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { Notifications } from '@infologistics/frontend-libraries'

import { authRoutes } from '@app/routes'
import { Layout } from '@common'
import Menu from '@common/layout/components/Menu'
import PrivateRoute from '@common/PrivateRoute'
import RedirectToStartPage from '@common/Routing/RedirectToStartPage'
import { BADGES_UPDATE_INTERVAL, Path } from '@const/consts'
import { getBadges } from '@store/modules/navigation/actions'
import { getRoutesList, showErrorNotification } from '@utils/utils'
import Page404 from '@views/misc/components/Page404'
import { IApplicationState } from '@app/store/types/commonTypes'
import './globalStyles.css';

const LayoutRouter: FC = () => {
  const dispatch = useDispatch<any>()
  const authorized: boolean = useSelector((state: IApplicationState) => state.user.isAuthenticated)
  const isError404: boolean = useSelector((state: IApplicationState) => state.utils.isError404)
  const [intervalLocal, setIntervalLocal] = useState(0)

  useMount(() => {
    if (authorized) {
      const intervalTemp = window.setInterval(() => {
        dispatch(getBadges()).catch(showErrorNotification)
      }, BADGES_UPDATE_INTERVAL)

      setIntervalLocal(intervalTemp)
    }
  })

  useUnmount(() => {
    authorized && intervalLocal && clearInterval(intervalLocal)
  })

  const renderRoutes = (): ReactNode => isError404 ? <Route component={Page404} /> : getPrivateRoutes()

  const getPrivateRoutes = (): ReactNode => (
    <Switch>
      {getRoutesList(authRoutes)}
      <PrivateRoute exact={true} path={Path.ORGANIZATION_ROUTE} component={Layout} />
      <Route component={RedirectToStartPage} />
    </Switch>
  )

  return (
    <BrowserRouter>
      <div>
        <Notifications />
        <Route exact={true} path={Path.ORGANIZATION_ROUTE} component={Menu} />
        {renderRoutes()}
      </div>
    </BrowserRouter>
  )
}

export default LayoutRouter
