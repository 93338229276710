import { Action } from 'redux';
import { IOrder, OrderNumberField } from '../orders/types';

export interface IOperationStatus {
  sending?: boolean;
  isSuccess?: boolean;
  isCanceled?: boolean;
  errorMessage?: string;
  isSent?: boolean;
}

export type IMassiveOperationOrder = Pick<
  IOrder<OrderNumberField>,
  | 'account'
  // accomplish_date,
  // address,
  // assigned_date,
  // assigned_to,
  // assigned_to_group,
  | 'created_date'
  | 'created_user'
  // decline_date,
  | 'id'
  // is_postponed_execution,
  | 'oguid'
  | 'order_type'
  // outer_company,
  // outer_order_number,
  | 'status'
  | 'sub_account'
  // start_date,
  | 'city'
  | 'terminal'
> &
  IOperationStatus & { isObjects?: boolean };

export interface IMassiveOperationOrders {
  [key: string]: IMassiveOperationOrder;
}

export interface IUpdatedMassiveOperationOrder {
  id: string;
  order: IMassiveOperationOrder;
}

export enum MassiveOperationsActionTypes {
  ADD_ORDERS = 'ADD_ORDERS',
  UPDATE_ORDER = 'UPDATE_ORDER',
  RESET_ORDERS_OPERATIONS = 'RESET_ORDERS_OPERATIONS',
}

export interface IActionAddOrdersOperation extends Action<string> {
  type: MassiveOperationsActionTypes.ADD_ORDERS;
  payload: IMassiveOperationOrders;
}

export interface IActionUpdateOrdersOperation extends Action<string> {
  type: MassiveOperationsActionTypes.UPDATE_ORDER;
  payload: IUpdatedMassiveOperationOrder;
}

export interface IActionResetOrdersOperation extends Action<string> {
  type: MassiveOperationsActionTypes.RESET_ORDERS_OPERATIONS;
}

export type Actions =
  | IActionAddOrdersOperation
  | IActionUpdateOrdersOperation
  | IActionResetOrdersOperation;

export type IMassiveOperationState = IMassiveOperationOrders;
